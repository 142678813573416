import React, { useEffect, useState  } from 'react';
import { useSearchParams } from "react-router-dom";
import './style.scoped.scss';

import { useTransition, animated } from '@react-spring/web'

import background from '../../assets/bgapplication3.png';
import illustration from '../../assets/illustration_applicationpage.png';

import BlobAnimation from '../../components/BlobAnimation/BlobAnimation';
import ApplicationWave from '../../components/BackgroundWaves/ApplicationWave';

const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

function Application() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState(null);
    const [appid, setAppid] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [isURLRequest, setIsURLRequest] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState(false);
    const [hasApplicationStatusChanged, setHasApplicationStatusChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleClick = async (e) => {
        e.preventDefault();
        
        if(appid !== null && appid !== "" && appid !== searchParams.get("id")) 
        {
            setIsLoading(true);
            await sleep(1000);
            setSearchParams({id: appid});
        }
    }

    const handleChange = (e) => {
        setAppid(e.target.value);
    }

    
    const blurTransitions = useTransition(isLoading, {
        from: {
            backdropFilter: 'blur(0px)',
            opacity: 0,
            backgroundColor: "#1b1b2e4b",
            display: "flex",
            zIndex: 200,
            position: "fixed",
            width: "100vw",
            height: "100vh",
        },
        enter: {backdropFilter: 'blur(10px)',
        opacity: 1,
        },
        leave: {backdropFilter: 'blur(0px)',
        opacity: 0,
        },
    })

    const sleep = async (milliseconds) => {
        await new Promise(resolve => {
            return setTimeout(resolve, milliseconds)
        });
    };

    useEffect ( () => {
        const targetURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://sypher-project.com/backend';

        if(searchParams.get("id") !== null) {
            if(searchParams.get("id") !== "") {
                setIsURLRequest(true);
            }else {
                setIsURLRequest(false);
            }

            setIsLoading(true);
            fetch(targetURL+'/getappid', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appid: searchParams.get("id")
                })
            })
            .then((res) => {
                if (!res.ok) {
                    console.log(`Did not get an ok. got: ${res.statusText}`);
                    setIsLoading(false);
                }
                return res.json();
            })
            .then((json) => {
               setData(json);
               
               if(json.id !== undefined && json.id !== "") {
                console.log(json);
                setIsDisplayed(true);
                setIsLoading(false);

                if(json.approved === "approved") {
                    setApplicationStatus(true);
                }
                else if(json.approved === "rejected") {
                    setHasApplicationStatusChanged(true);
                }
               }else {
                setIsDisplayed(false);
               }

            }) //setData here
            .catch((error) => {
                console.log(`Error getting ad data: ${error.message}`);
                setIsLoading(false);
            })
        }
        
    } , [searchParams]);

    return (
        <div className='application-global'>
            
            <img id="background" draggable="false" src={background} alt=''/>

            { 
                blurTransitions(
                    (styles, item) => item && <animated.div style={styles}>
                        {
                            <div className={ "lds-ellipsis"}><div></div><div></div><div></div><div></div></div>
                        }
                    </animated.div>
                )

            }

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>Application information</label>
                    </div>
                    <div className="subtitle">
                        Get informations about any application by entering its id down below.
                    </div>
                    
                    <div className='input-container'>
                        <input onChange={handleChange} className='textInput' type="text" placeholder='Application id'></input>
                        <button className="material-symbols-outlined" onClick={handleClick}>visibility</button>    
                    </div>

                    <div className='left-displayer '>
                        <div className={isDisplayed ? 'none' : 'flex'}>
                            <label className={isURLRequest ? 'errorColor' : 'pendingColor' }>{isURLRequest ? "The requested application could not be found." : "You did not load an application, enter one in the field above."}</label>  
                        </div>

                        <div className={isDisplayed ? 'flex' : 'none'}>
                            <label className={ applicationStatus ? "successColor" : hasApplicationStatusChanged ? "errorColor" : "pendingColor"}>{applicationStatus ? "Your application has been accepted, congratulations!" : hasApplicationStatusChanged ? "Your application has been declined." : "Our team is reviewing your application. Please come back later."}</label>
  
                        </div>
                    </div>
                </div>



                <div className={ isDisplayed ? 'tile block' : 'tile none'}>

                    <div className='tile-header'>
                        <img className='logo' src={data ? data.discordpfpURL : "https://www.pngitem.com/pimgs/m/22-223968_default-profile-picture-circle-hd-png-download.png"} alt=''/>
                        <div className="sub-tile-header">
                            <label className='name'>{data ? data.discordname : "Invalid Name"}</label>    
                            <label className='date'>{data ? getTime(data.date) : "Invalid Date"}</label>
                        </div>
                    </div>
                    <div className='tile-body'>
                        <label id="status" className={ applicationStatus ? "successColor" : hasApplicationStatusChanged ? "errorColor" : "pendingColor"}> <label className="white">Status:</label> {applicationStatus ? "Accepted" : hasApplicationStatusChanged ? "Declined" : "Pending"}</label>
                        <label className='id'>Application {data ? data.id : "Invalid ID"}</label>
                        <label className='discordid'>{data ? data.discordId : "Invalid User"}</label>
                    </div>
                </div>
            </div>

            <div style={ isDisplayed ? {} : {display:'none'}}>
                <BlobAnimation />
                <ApplicationWave />
            </div>

            <div className="content" style={ isDisplayed ? {} : {flexDirection:'column'}}>
                

                
                <div className={ isDisplayed ? 'global-container flex' : 'global-container none'}>

                    <div className="secflex-container">
                        <div className="questans1">
                            <div className="title">
                                <label>1. What role do you want to apply for?</label>
                            </div>
                            <div className="answer">
                                <label>{data ?  data.role : "This field has been left empty."}</label>
                            </div>
                        </div>
                    </div>

                    <div className="secflex-container">
                        <div className="questans1">
                            <div className="title">
                                <label>2. What is your experience?</label>
                            </div>
                            <div className="answer">
                                <label>{data ?  data.experience : "This field has been left empty."}</label>
                            </div>
                        </div>
                    </div>

                    <div className="secflex-container">
                        <div className="questans1">
                            <div className="title">
                                <label>3. What are your expectations?</label>
                            </div>
                            <div className="answer">
                                <label>{data ?  data.expectations : "This field has been left empty."}</label>
                            </div>
                        </div>
                    </div>


                    <div className="secflex-container">
                        <div className="questans1">
                            <div className="title">
                                <label>4. What are your links?</label>
                            </div>
                            <div className="answer">
                                <label>{data ?  data.links : "https://streamable.com/srdwgs https://gfycat.com/harmfultidybluebottlejellyfish https://gfycat.com/edibleapprehensivecanine https://gfycat.com/delectablefamousichthyostega https://gfycat.com/clumsydemandingcormorant https://gfycat.com/pleasantearnestgreathornedowl https://streamable.com/3e0gq3 https://streamable.com/yx99m2 https://streamable.com/5gru3i https://gfycat.com/sentimentalskinnyicterinewarbler https://cdn.discordapp.com/attachments/1038896585620328578/1072175"}</label>
                            </div>
                        </div>
                    </div>  

                    <div className="secflex-container">
                        <div className="questans1">
                            <div className="title">
                                <label>5. Who are you?</label>
                            </div>
                            <div className="answer">
                                <label>{data ?  data.who : "This field has been left empty."}</label>
                            </div>
                        </div>
                    </div>  

                </div>

                <div className="illustration">
                    <img draggable="false" src={illustration} alt=''/>
                </div>
            </div>
        </div>
    )
}

function getTime(time) {
    let data = new Date(time);
    return data.toLocaleString('fr-FR',{hour12: false});
}


export default Application;