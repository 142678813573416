import './Form.scoped.scss';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';


function Form() {
    return (
        <div className="form-global">

            <div className="form-quest1-container">
                <div className="content">
                    <label id="content">What would you like to learn?</label>
                    <FormControl>
                        <RadioGroup
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>

                </div>
            </div>
        </div>
    );
}

export default Form;