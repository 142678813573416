import styled from 'styled-components';
import wave from '../../assets/round-wave1.svg';
import waveDark from '../../assets/courses-wave1-dark.png';

export default function ApplicationWave() {
    return (
        <div>
            <WaveWrapper>
                <Wave />
                <Wave2 />
            </WaveWrapper>
        </div>
    );
}

const WaveWrapper = styled.div`
position: absolute;
height: 1500px;
width: 100%;
margin-top: -300px;
overflow: hidden;
pointer-events: none;


@media (min-width: 2100px) {
    height: 1800px;
}

`;


const Wave = styled.div`
    position: absolute;
    width: 90%;
    max-width: 1900px;
    right: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
    margin-top: -100px;
    background-image: url(${wave});
    height: 1500px;
    mask-image: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(255,255,255,0) 75%);

    @media (max-width: 700px) {
        width: 1500px;
    }

    @media (min-width: 2100px) {
        width: 60%;
    }
`;

const Wave2 = styled.div`
    position: absolute;
    width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 1;
    top: 200;
    margin-top: 200px;
    background-image: url(${waveDark});
    height: 1200px;
    mask-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%);

    
    @media (max-width: 700px) {
        width: 750px;
    }
`;
