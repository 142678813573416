import React from 'react';
import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import './style.scoped.scss';

import background from '../../assets/handbookbg.png';

function About() {
    const top = useRef(null);

    const receipt_and_acceptance = useRef(null);
    const table_of_content = useRef(null);
    const mission = useRef(null);
    const overview = useRef(null);
    const voluntary_staffing = useRef(null);
    const equal_staffing_opportunity = useRef(null);
    const policy_against_harassment = useRef(null);
    const staff_practices_and_position_descritpion = useRef(null);
    const leave_benefits = useRef(null);
    const separation_and_demotion = useRef(null);
    const nondisclosure_of_confidential_information = useRef(null);
    const staff_hierarchy = useRef(null);
    const remaining_active = useRef(null);

    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/

    useEffect ( () => {
        switch(searchParams.get("target")) {
            case "receipt_and_acceptance":
                receipt_and_acceptance.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "table_of_content":
                table_of_content.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "mission":
                mission.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "overview":
                overview.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "voluntary_staffing":
                voluntary_staffing.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "equal_staffing_opportunity":
                equal_staffing_opportunity.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "policy_against_harassment":
                policy_against_harassment.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "staff_practices_and_position_descritpion":
                staff_practices_and_position_descritpion.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "leave_benefits":
                leave_benefits.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "separation_and_demotion":
                separation_and_demotion.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "nondisclosure_of_confidential_information":
                nondisclosure_of_confidential_information.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "staff_hierarchy":
                staff_hierarchy.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "remaining_active":
                remaining_active.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className='about' ref={top}>
            
            <img id="background" src={background} alt=''/>

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>Handbook</label>
                    </div>
                    <div className="subtitle">
                        Learn more about the receipt & acceptance our members must accept when joining our team.
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="paragraph">
                    <label className="title" ref={receipt_and_acceptance}>Member Receipt & Acceptance</label>
                    <p className='text'>Hereby acknowledge the receipt of the Sypher Member Handbook. Understand that it is your continuing responsibility to read and know its contents.  Also understand and agree that the Member Handbook is not an employment contract for any specific period of employment or volunteering or for continuing or long‐term employment or volunteering.<br/>
                    <br/>
                    It is your continued duty to understand and agree to all of the above.
                    <br/>
                    <br/>
                    <br/>
                    <b>CONFIDENTIALITY POLICY AND PLEDGE</b><br/><br/>
                    Any information that a member learns about Sypher, or it’s members, or partners, as a result of managing Sypher that is not otherwise publicly available, constitutes confidential information. Members may not disclose confidential information to anyone who is not another member, staff or management member by Sypher. The disclosure, distribution, or copying of Sypher’s confidential information is strictly prohibited.  Any Member who uses his or her status to disclose any confidential Sypher information will be subject to disciplinary action, which includes demotion and a possible ban from Sypher, depending on the severity of the infringement.  
                    <br/>
                    <br/>
                    It is pivotal that you understand the above policy and pledge to not disclose confidential information.  


                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={table_of_content}>Table Of Content</label>
                    <p className='text'> You can click the following links to navigate our handbook.</p>
                    <ul className="list">
                        <li>• <Link to="/handbook?target=receipt_and_acceptance">Member Receipt and Acceptance</Link></li>
                        <li>• <Link to="/handbook?target=table_of_content">Table of Content</Link></li>
                        <li></li>
                        <li>• <Link to="/handbook?target=mission">Mission</Link></li>
                        <li>• <Link to="/handbook?target=overview">Overview</Link></li>
                        <li>• <Link to="/handbook?target=voluntary_staffing">Voluntary At-will Staffing</Link></li>
                        <li>• <Link to="/handbook?target=equal_staffing_opportunity">Equal Staffing Opportunity</Link></li>
                        <li>• <Link to="/handbook?target=policy_against_harassment">Policy Against Harassment</Link></li>
                        <li>• <Link to="/handbook?target=staff_practices_and_position_descritpion">Staff Practices and Position Description</Link></li>
                        <li>• <Link to="/handbook?target=leave_benefits">Leave Benefits</Link></li>
                        <li>• <Link to="/handbook?target=separation_and_demotion">Separation and Demotion</Link></li>
                        <li>• <Link to="/handbook?target=nondisclosure_of_confidential_information">Non-Disclosure of Confidential Information</Link></li>
                        <li>• <Link to="/handbook?target=staff_hierarchy">Staff Hierarchy</Link></li>
                        <li>• <Link to="/handbook?target=remaining_active">Remaining Active</Link></li>
                    </ul>
                </div>

                <div className="paragraph">
                    <label className="title" ref={mission}>I. Mission</label>
                    <p className='text'>Sypher’s mission is to provide free, division based coaching depending on the individual interest of those who apply.  We offer edit coaching, design coaching, and player coaching taught from skilled and experienced people in their specific departments.  Coaches write and provide structured guides for our users to improve their skills in the corresponding field of work. Our users are then assigned to a group that is followed by one of our coaches, which will decide who will rise in the skill or go down if necessary. Players, editors, or designers who are deemed capable enough, are promoted to the main team where they will represent Sypher.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={overview}>II. Overview</label>
                    <p className='text'>The Sypher Member Handbook has been developed to provide general guidelines about Sypher’s policies and procedures for our members.  It is a guide to assist you in becoming familiar with some of the privileges and obligations of your position. <b>None of the policies or guidelines in the Handbook are intended to give rise to contractual rights or obligations</b>. Additionally, with the exception of the voluntary at‐will employment policy, these guidelines are subject to modification, amendment, or revocation by Sypher at any time, with or sometimes without advance notice considering the team is still in development. <b>Any suggestions from Sypher members are greatly appreciated and encouraged to assist in the evolution of Sypher.</b><br/>
                    <br/>
                    <b>The personnel policies of the Sypher staff are established by the leaders of Sypher, and staff members are encouraged to consult the leader for additional information regarding the policies, procedures, and privileges described in this Handbook.</b> Sypher will provide each individual a copy of this Handbook upon promotion. All members are expected to abide by it. The highest standards of personal and professional ethics and behavior are expected of all Sypher staff members. <b style={{textDecoration: 'underline'}}>Further, Sypher expects each members to display good judgment, diplomacy, and courtesy in their professional relationships with members of Sypherʹs management, members, community, and partners.</b>
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={voluntary_staffing}>III. Voluntary At-will Staffing</label>
                    <p className='text'>Unless a staff member has a written employment agreement with Sypher, which provides differently, all employment at Sypher is “at‐will.” That means that employees may be terminated from employment from Sypher with or without cause, and employees are free to leave the employment of Sypher with or without cause.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={equal_staffing_opportunity}>IV. Equal Staffing Opportunity</label>
                    <p className='text'><b>Sypher is committed to providing equal opportunity for all members and applicants without regard to race, color, religion, national origin, or sex</b>. Each person is evaluated on the basis of personal skill and merit.<br/>
                    <br/>
                    <b style={{textDecoration: 'underline'}}>Sypher will not tolerate under any circumstances any form of discrimination from members.</b> All members are expected to cooperate fully in implementing Syphers policies. In particular, any member who believes that any other member of Sypher may have violated the Equal Staffing Opportunity Policy should report the possible infraction to an executive.<br/>
                    <br/>
                    <b>If Sypher determines that a violation of this policy has occurred, it will take appropriate disciplinary action against the offending party, which can include counseling, warnings, suspensions, and termination.</b> Staff members who report, in good faith, violations of this policy and employees who cooperate with investigations into alleged violations of this policy will not be subject to retaliation. Upon completion of the investigation, Sypher will inform the employee who made the complaint of the results of the investigation.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={policy_against_harassment}>V. Policy Against Harassment</label>
                    <p className='text'><b>Sypher is committed to providing an environment for all staff members that is free from any kind of harassment.</b> In return, all staff members are expected to conduct themselves in a professional manner and to show respect to their fellow staff members and those higher and lower in the management hierarchy.  
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={staff_practices_and_position_descritpion}>VI. Staff Practices and Position Description</label>
                    <p className='text'><b>All Sypher staff are required to present themselves as professional and open-minded individuals who’s aim is to guide the team, enforce policies, and promote seamless team functionality.</b> <br/>
                    <br/>
                    1. <b>If someone is spamming, harassing, discriminating, or violating any other public rules or policies, it is the staff’s duty to assess the situation and generate professional means of subduing the offender.</b> Depending of the severity of the situation, staff members have to make a choice whether to give a kind warning, a harsh warning, an immediate kick, or an immediate ban.  More severe infractions include material that is derogatory, defamatory, obscene or offensive, such as slurs, epithets or anything that might be construed as harassment or disparagement based on race, color, national origin, sex, sexual orientation, age, physical or mental disability, medical condition, or religious or political beliefs.  Pre-warnings can be given before an individual is subjected to a more harsh punishment like a kick or a ban.<br/>
                    <br/>
                    <b>If a staff member is evaluated as incapable of possessing the proper judgment to handle various situations like the ones mentioned above, it will result in a warning, or depending on the severity or frequency, a demotion from the staff or member position.</b><br/>
                    <br/>
                    2. <b>If you are asked a question that you cannot answer, and another staff or management member is online, it is advised to not surmise an answer to avoid escalating any situation the question might be linked to.</b> It is advised to let the individual/group know that you do not know the answer and to kindly wait until another staff or management member comes online.  If the question is urgent and requires an immediate answer, try searching around in the website for an answer. However, if you cannot procure a genuine or correct answer, it is even more advised to not answer off of postulation to avoid intensifying the situation.<b>Information about the project, the structure and our development can be found in the <Link to="/About">about section</Link>.<br/>
                    <br/>
                    Remember, you are an important figure in representing Project Sypher, so it is pivotal that you maintain a mature, professional, and open-minded figure.
                    </b>As Sypher grows, more and more people will look up to you for questions and guidance, and any immature or childish behavior will not be tolerated. 
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={leave_benefits}>VII. Leave Benefits</label>
                    <p className='text'>All Sypher staff members are allowed to take leave for any of the following situations:
                    <ul className='list'>
                        <li>• Holidays</li>
                        <li>• Injuries</li>
                        <li>• Sickness</li>
                        <li>• Any other reason presented by a member that makes sense and warrants leave.</li>
                    </ul> 

                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={separation_and_demotion}>VIII. Separation and Demotion</label>
                    <p className='text'><b>Either Sypher or a staff member can initiate separation.</b>Sypher encourages staff members to provide at least a 10 day written notice prior to intended separation.<br/>
                    <br/>
                    Circumstances under which separation may occur include:<br/>
                    <br/>
                    1. <b>Resignation.</b> Staff members are encouraged to give at least a 10 days written notice.  Since a longer period is desired, the intention to resign should be made known as far in advance as possible.<br/>
                    <br/>
                    2. <b>Termination or demotion.</b> Under certain circumstances, the termination or demotion of a staff member may be necessary.<br/>
                    <br/>

                    <b>The Leader or upper management has the authority to discharge or demote a staff member from Sypher.</b> As stated above, all staffing at Sypher is “at‐will.” That means that employees may be terminated from employment with Sypher with or without cause, and staff members are free to leave the management of Sypher with or without cause.<br/>
                    <br/>
                    <b style={{textDecoration: 'underline'}}>Staff have 1 warning if they commit any of the following violations before they are demoted:</b>                    <br/>
                    <br/>

                    <b>
                        <ul className='list'>
                            <li>1. Falsifying or withholding information on your employment application that did or would have affected Sypher’s decision to hire you (this conduct will result in your immediate termination); </li><br/>
                            <li>2. Falsifying or withholding information in other personnel records including personnel questionnaires, performance evaluations or any other records;</li><br/>
                            <li>3. Performance at work below a level acceptable to Sypher or the failure to perform assigned duties;</li><br/>
                            <li>4. Insubordination;</li><br/>
                            <li>5. Fighting, arguing or attempting to injure another;</li><br/>
                            <li>6. Breach of confidentiality;</li><br/>
                            <li>7. Using or appearing to use for personal gain any information obtained on the job, which is not readily available to the general public or disclosing such information that damages the interests of Sypher or its community;</li><br/>
                            <li>8. Placing oneself in a position in which personal interests and those of Sypher are or appear to be in conflict or might interfere with the ability of the employee to perform the job as well as possible;</li><br/>
                            <li>9. Dishonesty;</li><br/>
                            <li>10. Excessive tardiness or absenteeism whether excused or unexcused;</li><br/>
                            <li>11. Engaging in discriminatory or abusive behavior, including sexual harassment and racism towards the community or other staff members;;</li><br/>
                            <li>12. Exploiting or abusing the staff position;</li><br/>
                            <li>13. Excessive pinging of upper management or managers;</li><br/>

                        </ul>
                    </b>
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={nondisclosure_of_confidential_information}>IX. Non-Disclosure of Confidential Information</label>
                    <p className='text'><b>Any information that a staff member learns about Sypher, or its members or community, as a result of managing Sypher that is not otherwise publicly available constitutes confidential information.</b> Staff may not disclose confidential information to anyone who is not employed by Sypher or to other persons employed by Sypher who do not need to know such information.  

                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={staff_hierarchy}>X. Staff Hierarchy</label>
                    <p className='text'>1. Support staff. Their main duty is to respond to ticket inquiries maintaining  professionalism and a disregard to the question asked.  They are there to provide support for the community and are required to keep their behavior calm, and mature when answering questions.  Support staff also need to know how to properly regulate chat environments, essentially keeping them clean, functional, and free from any public infraction like hate, discrimination, excessive swearing, and excessive spamming.<br/><br/>
                    2. Moderators.  A moderators job is to keep chats under control and running smoothly to maintain a healthy, safe, and fun community environment. Moreover, a moderator time is most ideally spent evenly attending to both chats and support tickets.<br/><br/>
                    3.Staff Mentor.  Staff mentors are people who are more experienced with the practices and procedures of being in the staff department.  These individuals were chosen to help guide newer staff members to becoming the best they can be to become an important asset to the Sypher management team.<br/><br/>
                    4. Admin.  The admins main job is to assist the manager with any task the manager requests help with, and makes sure the support staff and moderators are all following proper and professional conduct when performing their duties.  All support staff, moderators, and mentors answer to the admins, who in turn, answer to the manager.<br/><br/>
                    5. Manager.  The managers primary task is to handle HR and PR, and make sure that the support staff, moderators, mentors, and admins are all performing their duties professionally and up to Syphers standards.  Managers are essentially the head of staff, and answer only to upper management.  Managers  strive to keep the discord server running smoothly and seamlessly through dedicated leadership and guidance.  This is all so as to take some of the pressure off the upper management, who are dedicating most of their time to making sure what happens behind the curtains runs smoothly.  Managers also have the right to demote other staff members if they feel it is needed.<br/><br/>
                    6. Head Management.  The head management is the  highest in the staff hierarchy, and has an overlook over everything that happens in Sypher.  The head management dedicates all of their time to making sure the technical side of the project runs smoothly, and therefore, is not responsible for enforcing policies.  Any decision has to be run past the head management before any final decisions can be made to promote a unified and successful change that might be implemented into the project.  Head management has control over all other staff members, so demotion and promotion are both within their right to carry out.  <br/><br/>

                    Note:  If you were placed at a higher slot in the staff hierarchy, and still given the role of a staff position below your current position, you are still required to carry out everything in that lower position.  For what, if you are  given the admin and support staff roles, you are not exempt from support staff duties.
 

                    </p>
                </div>
               

                <div className="paragraph">
                    <label className="title" ref={remaining_active}>XI. Remaining Active</label>
                    <p className='text'>Upon becoming a member, it is pivotal that you stay active. Staff is a vital cog in the massive clock that is any successful organization, therefore is it pivotal that you remain active during your employment as member. That being said, there are consequences for remaining inactive.
                    <br/><br/>
                    What constitutes being inactive, is not having any input or say in the server for 1 week. After 1 week of being inactive, you will be given a warning, and after 2 weeks of inactivity, your position will be terminated.
                    </p>
                </div>
            </div>
        </div>
    )
}



export default About;