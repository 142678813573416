import React from 'react';
import './style.scss';

import illustration from '../../assets/car-illustration2.png'; 

function LoginModal(props) {
    return (props.trigger) ? (       
        <div className='LoginModal'>
            <div className="modal">

                
                <div className="modal-content">
                    <div className="side-rect">
                        <img src={illustration} alt=""/>
                    </div>

                    <div className="right-content">
                        <label className="sign-up-title modal-item">Sign Up</label>

                        <div className="info-container">
                            <p>
                            By clicking on Sign up, you agree to our Terms of service and
                            Privacy policy.
                            </p>

                            <div className="separator"></div>

                            <label htmlFor="">Already have an account? <i>Then you're all set! Wait for us.</i></label>
                        </div>
                    </div>
                </div>
                
                <div onClick={() => props.setTrigger(false)} className="modal-background"></div>
            </div>
        </div>
    ) : "";
}
    
export default LoginModal;