import React from 'react';
import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import './style.scoped.scss';

import background from '../../assets/backgroundaboutupdated.png';

function Handbook() {
    const roles = useRef(null);
    const top = useRef(null);
    const receiptPolicy = useRef(null);
    const discord_server = useRef(null);
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/

    useEffect ( () => {
        switch(searchParams.get("target")) {
            case "roles":
                roles.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "receipt_and_policy":
                receiptPolicy.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className='about' ref={top}>
            
            <img id="background" src={background} alt=''/>

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>About us</label>
                    </div>
                    <div className="subtitle">
                        You'd like to learn more about us and our mission? Then this is definitely the right place!
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="paragraph">
                    <label className="title">Our story</label>
                    <p className='text'>Sypher is about giving people a chance to improve their skillset and knowledge to meet high level team requirements. We want to make it easier for you to find belonging in the team of your choice. We want you to feel in the right place, in a family where you can while making friends and enjoying your days, build up knowledge and a solid skillbase in what you love.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={receiptPolicy}>Prologue</label>
                    <p className='text'> Sypher was started to solve one single problem: how to progress and learn recruitment standards within a community for free. Team standards have been the same for years since 2018, we wanted to set new standards. We're bringing a new way for our members to progress while showcasing their talent through our content by recreating the feeling of togetherness we wish we had at that time.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={receiptPolicy}>How our project works</label>
                    <p className='text'>Users provide us informations about their level and current position in the desired field of work, then we analyze them an predict results to give our users a rough idea of how long their learning process could take. Our coaches will ensure our users receive courses and exercices to complete by reviewing their work throughout our program. Getting personal coaching or access to more courses will require our premium membership.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={receiptPolicy}>What's the team</label>
                    <p className='text'>We make videos. Our players send clips, we edit them, add some outstanding animations and our video is ready. We encourage our members to bring their own ideas and creativity to the development of our projects, videos, platforms, logos... anything we build! We'd like to help you find just the right place.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={receiptPolicy}>How to help</label>
                    <p className='text'>Apply! Your ideas is what we value the most, bring them to our project and we'll do our best to make them a thing! Learn more about our <b><Link to="/standards">standards and how to join us</Link>.</b></p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={discord_server}>Discord Server</label>
                    <p className='text'>Our discord server is the place for you to follow our development, interact and even play with the team. We have been using Discord as our main communication platform since our early minutes as a team, it helped us grow as a community that people love hanging out with. That is why for this brand new version of Sypher, we are going to use discord another time! Join and hang out with us, we got some cookies!</p>
                </div>
            </div>
        </div>
    )
}



export default Handbook;